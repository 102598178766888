<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
	export default {
	  name: "App",
	  components: {},
	  data() {
	    return {
	    };
	  },
	  mounted() {
	    
	  },
	};
</script>

<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,
body,
#app,
.xs-layout,
.el-container {
  padding: 0;
  margin: 0;
  height: 100%;
  direction: vertical;
  background: #F7F7F9
}
::-webkit-scrollbar {
  height: 4px;
  width: 1px;
  background-color: #f5f6f7;
}
.el-table__cell+ .el-table__cell{
    padding: 0;
}
</style>
