import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from 'pinia'
//引入 element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css' 
import '@icon-park/vue-next/styles/index.css';
import store from './store'
import { install } from '@icon-park/vue-next/es/all'
import locale from 'element-plus/lib/locale/lang/zh-cn';


const pinia = createPinia()
const app =createApp(App)

// 在页面加载时读取localStorage里的状态信息
if (localStorage.getItem('store')) {
  store.replaceState(Object.assign({},store.state,JSON.parse(localStorage.getItem('store'))))
  localStorage.removeItem("store")
}
// 在页面刷新时将vuex里的信息保存到localStorage里
// beforeunload事件在页面刷新时先触发  
window.addEventListener('beforeunload', () => {
  localStorage.setItem('store', JSON.stringify(store.state))
})

install(app);
app.use(store);
app.use(pinia);
app.use(router);
app.use(ElementPlus ,{locale})
app.mount('#app')