import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  ErrorHandler,
} from 'vue-router'
// 引入路由守卫方法
import beforeEach from './beforeEach'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component:()=>import('@/views/user/login.vue'),
    meta: {
      requireAuth: true,
      title: '登录'
    }
  },

  {
    path:'/admin',
    name:'admin',
    component:()=>import('@/components/admin.vue'),
    // redirect: '/system',
    meta: {
  	  requireAuth: true,
  	  title: '首页'
  	},

    children:[
      {
        path: '/userList',
        name: 'userList',
        component: ()=>import('@/views/user/userList.vue'),
        meta: {
          requireAuth: true,
          title: '用户信息列表'
        }
      },
      {
        path: '/system',
        name: 'system',
        component: ()=>import('@/views/system'),
        meta: {
          requireAuth: true,
          title: '试验列表'
        }
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: ()=>import('@/views/user/userInfo'),
        meta: {
          requireAuth: true,
          title: '用户信息'
        }
      },
      {
        path: '/siteList',
        name: 'siteList',
        component: ()=>import('@/views/site/siteList'),
        meta: {
          requireAuth: true,
          title: '站点列表'
        }
      },
      {
        path: '/pmsList',
        name: 'pmsList',
        component: ()=>import('@/views/site/pmsList'),
        meta: {
          requireAuth: true,
          title: '台账列表'
        },
      },
      {
        path: '/roleList',
        name: 'roleList',
        component: ()=>import('@/views/user/roleList'),
        meta: {
          requireAuth: true,
          title: '角色管理'
        },
      },
      {
        path: '/testConfigList',
        name: 'testConfigList',
        component: ()=>import('@/views/testConfig/testConfigList'),
        meta: {
          requireAuth: false,
          title: '实验参数配置管理'
        },
      },
    ]
  },


  {
    path: '/404',
    name: '404',
    component: ()=>import('@/views/error'),
    meta: {
      requireAuth: true,
      title: '试验列表'
    }
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory('/'),
  routes: routes,
})

/**
 * 路由守卫
 */
router.beforeEach((guard ,from) => {
  beforeEach.checkAuth(guard, router ,from)
})

/**
 * 路由错误回调
 */
router.onError((handler) => {
  console.log('error:', handler)
})

/**
 * 输出对象
 */
export default router
