import { createApp } from 'vue'
import App from '@/App.vue'
import Vuex from 'vuex'

const app =createApp(App)
app.use(Vuex);
const store = new Vuex.Store({
    state: {
        checkMenu:'',
        avatar:"",
        userId: 0,
        ckComId:1,
        userInfo: '',
        powerList:'',
        menuList:'',
        guide: true,
        enterprise:  "",
        newsList:  "",
        enterpriseOptions: "",
        enterpriseList:"",
        itemList:""
    },
    getters: {
        //对state里面的数据进行一下变形用的，相当于组件中的计算属性
        isLogin(state) {
            return state.userId==0?false:true;
        },
        
    },
    mutations: {
        //更新state的方法定义在里面
        avatarUser(state, data){
            state.avatar = data;
        },
        loginUser(state, data) {
            state.userId = data.uuid;
            state.userInfo = data
        },
        menuList(state, data){
            state.menuList = data
        },
        powerList(state, data){
            state.powerList = data
        },
        logout(state) {
            state.userId = 0;
            state.userInfo = '';
            state.menuList = '';
            state.powerList = '';
        },
        isGuide(state, data) {
            state.guide = data;
        },
        enterprise(state, data) {
            state.enterprise = data;
        },
        enterpriseList(state, data){
            state.enterpriseList = data;
        },
        newsList(state, data) {
            state.newsList = data;
        },
        itemList(state, data) {
            state.itemList = data;
        },
        enterpriseOptions(state, data) {
            state.enterpriseOptions = data;
        },
        ckComId(state,data){
            state.ckComId = data;
        },
        checkMenu(state,data){
            state.checkMenu = data;
        }
    },
    actions: {
        //用于在vuex中执行一些异步操作 context上下文

    },
    modules: {},
})

export default store