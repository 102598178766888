import { Router } from 'vue-router'
import store from '@/store'

export default {
  /**
   * 路由守卫检查权限
   * @param guard
   * @param router
   */
  checkAuth(guard, router ,from) {
    //检查路由是否存在
    if (!router.hasRoute(guard.name)) {
      //三层不同404路由
      if (guard.fullPath.indexOf('/frame') >= 0) {
        router.push('/404')
      } else if (guard.fullPath.indexOf('/pages') >= 0) {
        router.push('/404')
      } else {
        router.push('/404')
      }
      return
    } else {
		  document.title = guard.meta.title;
      console.log(document.referrer.indexOf(process.env.VUE_APP_URL)>=0);
		  if (guard.meta.requireAuth && guard.fullPath!="/404") {
			  if(!store.getters.isLogin && guard.fullPath!="/login"){
          if (document.referrer.indexOf(process.env.VUE_APP_URL)>=0) {
            router.push('/login');
          } else {
            router.push('/404');
          }
			  } else if (store.getters.isLogin && guard.fullPath=="/login"){
          if (document.referrer.indexOf(process.env.VUE_APP_URL)>=0) {
            router.back(-1);
          } else {
            router.push('/system');
          }
			  }
		  }
		  return
	  }
  },
  
  // check
  // router.beforeEach((to, from) => {
  //   document.title = to.meta.title;
  //   if (to.meta.requireAuth) { 
  //     if(store.getters.isLogin){
  // 		return;
  // 	} else {
  // 		router.push('/login');
  // 		return;
  // 	}
  //   }else{
  //     return;
  //   }
  //   if (to.fullPath == "/login") {
  //     if(store.getters.isLogin){
  // 		router.push(from.fullPath)
  // 	}else {
  // 		return;
  // 	}
  //   }
  //   return;
  // });
}